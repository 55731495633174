<script>
  import { Router } from '@sveltech/routify';
  import { routes } from '@sveltech/routify/tmp/routes';

  import { windowWidth } from './utils/store';
</script>

<svelte:window bind:innerWidth={$windowWidth} />
<Router {routes} />

<svelte:head>
  <script src="https://js.stripe.com/v3/"></script>
</svelte:head>

<style global lang="postcss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
