<script>
  import { redirect } from '@sveltech/routify';
  import { query, collection, where, onSnapshot } from 'firebase/firestore';
  import { onMount } from 'svelte';
  import { db } from '../utils/services';
  import { currentApp, selectedOrgId } from '../utils/store';

  import Tooltip from './Tooltip.svelte';
  export let app = {};

  let showTooltip = false;

  $: numUsers = 0;
  $: userString = numUsers.length == 1 ? 'User' : 'Users';

  $: appName = app.name.toLowerCase().replace(' ', '_');

  function navigate() {
    window.open(app.stripe_metadata_url, '_blank');
  }

  function manageUsers() {
    $currentApp = app;
    $redirect('/products/users');
  }

  function getUsers() {
    const q = query(
      collection(db, `org_users/${$selectedOrgId}/users`),
      where(`${appName}`, '==', true)
    );

    onSnapshot(q, (querySnapshot) => {
      numUsers = 0;
      querySnapshot.forEach((doc) => {
        numUsers += 1;
      });
    });
  }

  onMount(() => {
    getUsers();
  });
</script>

<!-- on:click={navigate} -->
<div class="my-1 px-1 w-full lg:my-4 lg:px-4 h-full">
  <div
    class="bg-white dark:bg-gray-800 dark:text-white rounded-lg w-full shadow hover:shadow-lg duration-4"
  >
    <div class="flex flex-row justify-between items-center px-4 pt-4">
      <div class="">
        <p class="font-bold text-2xl">{app.name}</p>
        <p class="text-xs my-2" class:text-transparent={!app.description}>
          {app.description}
        </p>
      </div>

      <Tooltip text="Navigate to app" {showTooltip}>
        <button
          class="rounded uppercase px-4 md:px-8 py-4 flex items-center text-xs text-white font-bold bg-socius-secondary shadow-lg"
          on:click={navigate}
          target="_blank"
          on:mouseenter={() => (showTooltip = true)}
          on:mouseleave={() => (showTooltip = false)}
        >
          Launch App <i class="ml-2 fas fa-arrow-right" />
        </button>
      </Tooltip>
    </div>

    <div class="px-6 pb-6 text-grey-darker flex">
      <p class="text-sm my-2 text-blue-darker mr-8">
        <span class="text-socius-secondary font-bold text-2xl mr-2">
          {numUsers}
        </span>
        {userString}
      </p>

      <span
        class="my-2 text-sm text-socius-secondary uppercase cursor-pointer flex items-end"
        on:click={manageUsers}>Manage Users</span
      >
    </div>
  </div>
</div>
